// @ts-check

import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { map } from "./functional.js";
import { format_num, get_base_unit_of_mod, prettify } from "./conversions.js";
import { hash_address } from "@/components/housing/util.js";

/**
 * @typedef {import('@/types/ht/SampleState.d.js').SampleState} SampleState
 * @typedef {import('@/types/ht/SampleState.d.js').SampleStatesByLabelID} SampleStatesByLabelID
 * @typedef {import('@/generated_types/graphql.js').Operation} Operation
 * @typedef {import('@/generated_types/graphql.js').Housing} Housing
 */

/**
 * Arranges sample states in a chronologically correct sequence using based on operation_in/out ids.
 * @param {Array<SampleState>} unordered_sss
 * @param {number} label_id - for error handling purposes (added to the error message)
 * @returns {Array<SampleState>}
 */
const order_sss_by_ops = (unordered_sss, label_id) => {
  const ss_by_op_in_id = {};
  const ss_by_op_out_id = {};

  for (const ss of unordered_sss) {
    ss_by_op_in_id[ss.operation_in_id] = ss;
    ss_by_op_out_id[ss.operation_out_id] = ss;
  }

  let first_ss;
  for (const ss of unordered_sss) {
    if (ss_by_op_out_id[ss.operation_in_id]) continue;
    first_ss = ss;
    break;
  }
  if (!first_ss) throw new Error(`order_sss_by_ops(label_id=${label_id}) can't find first_ss in ${JSON.stringify(unordered_sss, null, "  ")}`);

  const sequence = [first_ss];
  let prev_ss = first_ss;
  for (let i = 1; i < unordered_sss.length; i++) {
    const next_ss = ss_by_op_in_id[prev_ss.operation_out_id];
    if (!next_ss) throw new Error(`order_ss_by_ops(label_id=${label_id}): ss #${i}: ${next_ss}`);
    sequence.push(next_ss);
    prev_ss = next_ss;
  }
  return sequence;
};

/**
 * Groups sss by label_id and orders them chronologically within each group.
 * When receiving an empty array, the function returns an empty object.
 * @param {Array<SampleState>} sss
 * @returns {SampleStatesByLabelID | Record<string, never>}
 */
export const group_sss_by_label_id_and_order_by_ops = (sss) => {
  if (sss.length === 0) return {};

  /** @type {SampleStatesByLabelID} */
  const sss_groups_obj = {};

  // move sss into buckets by label_id
  for (const ss of sss) {
    const { label_id } = ss;
    if (!sss_groups_obj[label_id]) sss_groups_obj[label_id] = [];
    sss_groups_obj[label_id].push(ss);
  }

  // order sss within each bucket
  for (const label_id in sss_groups_obj) {
    const sss_group = sss_groups_obj[label_id];
    sss_groups_obj[label_id] = order_sss_by_ops(sss_group, +label_id);
  }

  return sss_groups_obj;
};

export const get_live_sss = (sss) => {
  const if_origin = (ss) => ss.operation_in.operation_type?.class === "origin" && ["future", "now", "nascent"].includes(ss.status);
  const if_not_origin = (ss) => {
    const { operation_in: op_in } = ss;

    const op_in_cond = op_in.operation_type?.class !== "origin" || op_in.operation_type_key === null;
    return ss.status === "now" && op_in_cond;
  };

  return sss.filter((ss) => if_origin(ss) || if_not_origin(ss));
};

export const get_dispatch_sss = (sss) => sss.filter((ss) => ss?.operation_out?.operation_type_key === "dispatch" && ss.status === "past");

/**
 * This function sums the amounts of accountable sample states by default.
 * An accountable sample state is one that has been given away or live
 * @param {array} sss - sample states array
 * @param {boolean} accountable_sss_only - if true, filter out the given away or live sample states
 * @returns Sum of sample states amounts
 */
export const sum_sample_states_amount = (sss, accountable_sss_only = true) => {
  const get_amount = (s) => s.reduce((acc, ss) => acc + (ss.meta?.properties?.amount?.value ?? 0), 0);

  return get_amount(accountable_sss_only ? [...get_dispatch_sss(sss), ...get_live_sss(sss)] : sss);
};

/**
 * Returns a set of parameters, that describe the sample_states
 * that, when aggregated, give current synthesized amount for a given sample.
 * Use-cases:
 *  get all pending sss (sample_specific=false)
 *  get all pending sss for a sample (sample_specific=false). In this case, include this filter in the sample_states-subquery of your sample query
 * @param {sample_specific: boolean} param1 - a set of options. If sample_specific === true,
 *  check of sample is excluded, since it isn't necessary.
 */
export const gen_gql_vars_for_live_sss = ({ sample_specific = false, giveaway = false } = {}) => {
  const for_origin_in_op = {
    _and: {
      operation_in: { operation_type: { class: { _eq: "origin" } } },
      status: { _in: ["future", "nascent"] }, // "future" is for checked-in and mapped; "nascent" - when the operation in-progress
    },
  };

  const for_everything_else = {
    _and: [
      {
        _or: [
          { operation_in: { operation_type: { class: { _neq: "origin" } } } },
          { operation_in: { operation_type_key: { _is_null: true } } },
        ],
      },
      { status: { _eq: "now" } },
    ],
  };

  const oligo_task_check = { sample: { compound: { compound_substances: { oligo_id: { _is_null: false } } } } };
  const giveaway_filter = {
    _and: [
      { status: { _eq: "past" } },
      { operation_out: { operation_type_key: { _eq: "giveaway" } } },
    ],
  };
  const where = {
    _and: [
      {
        _or: [
          for_origin_in_op,
          for_everything_else,
        ],
      },
    ],
  };
  if (giveaway) {
    where._and[0]._or.push(giveaway_filter);
  }
  if (!sample_specific) {
    where._and.push(oligo_task_check);
  }
  return where;
};

export const mod_addr_to_string = (addr) => {
  const new_addr = { ...addr };
  // make it non-enumerable to preserve consistency when iterating over address fields
  Object.defineProperty(new_addr, "toString", {
    value() { return hash_address(new_addr); },
    enumerable: false,
  });
  return new_addr;
};

export const gen_mappable_ss = (ss) => ({
  ...ss,
  // ss.address in string context converts to full address string
  address: mod_addr_to_string(ss.address),
  // shows ss volume
  volume() {
    const volume = this.meta.properties?.volume;
    return volume ? format_num(volume.value ?? 0) : "N/a";
  },
  raw_volume() {
    const volume = this.meta.properties?.volume?.value;
    return volume ?? 0;
  },
  // shows ss scale
  scale() {
    const scale = this.meta.properties?.scale;
    return scale ? `${format_num(scale.value ?? 0)} ${scale.unit ?? ""}` : "N/a";
  },
});

export const add_stringifiable_addr = (ss) => ({
  ...ss,
  address: mod_addr_to_string(ss.address),
});

export const add_stringifiable_addr_to_sss = map(add_stringifiable_addr);

/**
 * @param {array} coll - array to sort
 * @param {string|array} [path=null] - path to address if it's a nested field
 * @returns {array} sorted array
 */
export const sort_by_address = (coll, path_address = null) => {
  if (!Array.isArray(coll) || coll.length === 0) return coll;
  const to_sort = [...coll];
  const is_num = (str) => /^-?\d+$/.test(str);
  const get_address = (el) => (path_address ? get(el, path_address) : el);
  const get_addr_as_num = (val) => Number.parseInt(val?.x ?? val.y);
  return to_sort.sort((a, b) => {
    const first_el = get_address(a);
    const second_el = get_address(b);
    if (is_num(first_el.toString())) {
      return get_addr_as_num(first_el) - get_addr_as_num(second_el);
    }
    return get_addr_as_num(first_el) - get_addr_as_num(second_el)
      || first_el?.y?.localeCompare(second_el.y);
  });
};

/**
 * @param {Array<Object>} coll - array of sample states
 * @param {*} path - path to address in the sample state (if null, the sort_by_address function will interpret the object in the coll as the address)
 * @returns - array sorted by hs ids and then by well-address
 */
export const sort_by_hs_and_address = (coll, path = null) => {
  if (!Array.isArray(coll) || coll.length === 0) return coll;
  const to_sort = [...coll];
  const hs_sss_map = to_sort
    .reduce((hs_map, ss) => {
      if (hs_map.has(ss.housing_state_id)) {
        hs_map.get(ss.housing_state_id).push(ss);
        return hs_map;
      }
      hs_map.set(ss.housing_state_id, [ss]);
      return hs_map;
    }, new Map());

  const sorted_hss = [...hs_sss_map.keys()].sort((a, b) => (a - b));
  return sorted_hss.flatMap((hs_id) => sort_by_address(hs_sss_map.get(hs_id), path));
};

/**
 * Can be used to visualize sample_mapping as we use it in the planning_view component
 * when generating operation objects to write sample mapping info to the database.
 * @param {Array<Object>} ss_map
 * @returns Array<String>
 */
export const ss_mapping_printer = (ss_map) => ss_map.map(([{ address: from }, to]) => `${from.x}${from.y} -> ${Object.entries(to).flatMap(([hs_id, addr]) => addr.map(({ y }) => `${hs_id}:${y}`)).join(", ")}`);

/**
 * this method calculates "od" from "amount"
 * Returns a hash code from a string
 * @param  {Object} s - sample object w/ spectra in meta
 * @return {Number} amount - the current number of nmols
 */
export function calculate_od_from_amount(s, amount) {
  if (!amount) return amount;
  const spectra = s.meta.properties.uv_spectra;
  const sp = spectra.y[spectra.x.indexOf(260)];
  const coeff_e = s.meta.properties.molar_extinction;
  return format_num((amount * (coeff_e * sp)) / 10 ** 6);
}

/**
* Extracts custom values planned for the specific operation
*  from the ss.sample.wf_profile from the appropriate operation.
* @param {Object} ss - sample state
*/
export const get_custom_field_values = (ss, custom_ss_params) => {
  const custom_values_entries = custom_ss_params.map((cf) => {
    if (typeof cf === "string") {
      return [cf, get(ss, cf) ?? "unset"];
    }
    if (typeof cf === "object" && Object.keys(cf).length > 0) {
      const [cf_key, cf_value] = Object.entries(cf)[0];
      if (typeof cf_value === "string") {
        return [cf_key, get(ss, cf_value) ?? "unset"];
      }
      if (typeof cf_value === "function") {
        return [cf_key, cf_value(ss)];
      }
      throw new Error("Malconfigured custom ss parameter! Refer to the docs https://wiki.yandex.ru/ctx/modulnaja-sistema-operacijj-i-kastomizacija/");
    }
    throw new Error("Malconfigured custom ss parameter! Refer to the docs https://wiki.yandex.ru/ctx/modulnaja-sistema-operacijj-i-kastomizacija/");
  });
  return Object.fromEntries(custom_values_entries);
};

/**
 * Helper function for highlight_op_connected_sss.
 * @param {SampleState[]} sss_in
 * @param {SampleState[]} sss_out
 * @returns "in" | "out" | "both" | null
 */
const get_selection_source = (sss_in, sss_out) => {
  if (sss_out.length === 1 && sss_in.length === 1) return "both";
  if (sss_in.length > 1 || sss_out.length > 1) return null;
  if (sss_in.length === 1) return "in";
  if (sss_out.length === 1) return "out";
  return null;
};

/**
 * Highlights sample states, connected with the user-selected ones by operations.
 * @param {Operation[]} - in and out sample states
 * @param {SampleState[]?} sss_in
 * @param {SampleState[]} sss_out
 * @param {"in" | "out"} pos - housing state relation to the group_operation
 * @param {HousingState} hs - housing state object
 */
export const highlight_op_connected_sss = (operations, sss_in, sss_out, pos, hs) => {
  const op_key = `operation_${({ out: "in", in: "out" })[pos]}`;

  const selection_source = get_selection_source(sss_in, sss_out);

  for (const ss of hs.sample_states) {
    if (["in", "out"].includes(selection_source) && operations.length > 0) {
      const _map = {
        in: () => sss_in[0].operation_out?.id === (ss[op_key]?.id ?? 0),
        out: () => sss_out[0].operation_in?.id === (ss[op_key]?.id ?? 0),
      };
      ss.highlighted = _map[selection_source]();
    } else ss.highlighted = false;
  }
};

/**
 * @param {SampleState} ss - sample state where the target_operations search takes place
 * @param {TargetOperation} t_op - target_operation by the criteria of which the states of the sample are searched
 * @param {Boolean} check_packaging - parameter that determines whether packaging fields can be compared
 * @return {Boolean} - result of comparing all criteria with ss
 */
export function ss_satisfies_target_op(ss, t_op_arg, check_packaging) {
  const t_op = cloneDeep(t_op_arg);
  t_op.criteria.packaging ??= {};

  for (const mod of Object.keys(t_op.criteria)) {
    if (mod === "packaging") {
      continue;
    }

    if (!Object.hasOwn(ss.meta.properties, mod)) {
      return false;
    }

    const [val, crit] = [ss.meta.properties[mod]?.value, t_op.criteria[mod]];
    if (val < crit.value || val > crit.max) {
      return false;
    }
  }
  if (check_packaging) {
    const { packaging: { vessel_type, housing_type, address } } = t_op.criteria;
    if (vessel_type) {
      return vessel_type === ss.vessel_type_key;
    }

    const ss_housing_type = ss.housing_state?.housing.housing_type_key;
    if (housing_type) {
      if (!address) {
        return housing_type === ss_housing_type;
      }
      return housing_type === ss_housing_type && hash_address(address) === hash_address(ss.address);
    }
  }
  return true;
}
/**
 * Returns ids of target_operations whose criterion are met by the ss.
 * @param {SampleState} ss - sample state where the target_operations search takes place
 * @param {Array<TargetOperation>} current_target_ops - target_operations by the criteria of which the states of the sample are searched
 * @param {Boolean} check_packaging - parameter that determines whether packaging fields can be compared
 * @return {Array<Number>} satisfied target_op ids - a target_operation_id list that matches the criteria
 */
export function get_satisfied_target_op_ids(ss, current_target_ops, check_packaging = false) {
  return current_target_ops
    .filter((t_op) => ss_satisfies_target_op(ss, t_op, check_packaging))
    .map(({ id }) => id);
}

/**
 * This function is used to get the ss_id that meets the criteria of current target_operation
 * @param {Array<SampleState>} sss - sample states where the target_operation search takes place
 * @param {TargetOperation} current_target_op - target_operation by the criteria of which the states of the sample are searched
 * @param {Boolean} check_packaging - parameter that determines whether packaging fields can be compared
 * @return {Array<Number>} ss_ids - a ss_id list that matches the 'current_target_op' criteria
 */
export function get_ss_by_met_criterium(sss, current_target_op, check_packaging = false) {
  return sss
    .filter((ss) => ss_satisfies_target_op(ss, current_target_op, check_packaging))
    .map(({ id }) => id);
}

/**
 * This function is used to get the result of satisfies synthesis criteria
 * @param {SampleState} ss - sample state where the target with type synthesis search takes place
 * @return {Array<Boolean>} - result of satisfies synthesis criteria
 */
export function ss_satisfies_synthesis_criteria(ss) {
  const synthesis_target = ss.sample.target_samples.find((t_s) => t_s?.target?.type === "synthesis")?.target;
  if (!synthesis_target || !ss.meta) return false;
  return get_satisfied_target_op_ids(ss, synthesis_target.target_operations).length > 0;
}

/**
 * Searches for and returns the same 'target_id' for samples obtained from sample_states
 * Returns one of target_ids if it is repeated multiple times across the sample_states (sss parameters).
 * Returns null, if none of the current_target_samples is repeated at least 2 times in sample_state
 * @param {Array<TargetSample>} current_target_samples - target_samples going into iteration
 * @param {Array<SampleState>} sss - sample states in which 'target_id' is searched
 * @return {Number} target_id - matched target_id
 */
export function get_matched_target_id(current_target_samples, sss) {
  const all_targets_ids = sss
    .flatMap(({ sample: { target_samples } }) => target_samples)
    .filter((target_sample) => target_sample?.target?.type === "synthesis")
    .map((target_sample) => target_sample?.target_id);
  const matching_samples_targets = new Set(all_targets_ids.filter((target_id, index, targets_ids) => targets_ids.indexOf(target_id) !== index));
  return current_target_samples.find(({ target_id }) => matching_samples_targets.has(target_id))?.target_id;
}

/**
 * Returns an object with the matched 'target_id' with keys "in"/"out" representing hss-in and hss-out
 * @param {Array<SampleState>} sss_in - incoming sample states in which 'target_id' is searched
 * @param {Array<SampleState>} sss_out - out-coming sample states in which 'target_id' is searched
 * @return {{ in: number[], out: number[] }} - object with matching target ids sorted by type of housing state
 */
export function get_matched_target_ids_by_hs_direction(sss_in, sss_out) {
  return {
    in: sss_in.map(({ sample: { target_samples } }) => get_matched_target_id(target_samples, sss_in))
      .filter(Boolean),
    out: sss_out.map(({ sample: { target_samples } }) => get_matched_target_id(target_samples, sss_out))
      .filter(Boolean),
  };
}

/**
 * Builds a string from criteria.packaging
 * @param {import('@/operation_types/giveaway/GiveawayWorkType.d.ts').Packaging} packaging
 * @param {boolean} [stang_alone=true]
 */
export function format_packaging(packaging) {
  if (!packaging) return;
  let formed_packaging = "";

  if ("vessel_type" in packaging) {
    formed_packaging += `${packaging.vessel_type}`;
  } else {
    formed_packaging += `${packaging.housing_type}`;
    if ("housing_index" in packaging) {
      formed_packaging += ` #${packaging?.housing_index}`;
    }
    if ("address" in packaging) {
      formed_packaging += ` | ${packaging?.address?.x}${packaging?.address?.y}`;
    }
  }
  return formed_packaging;
}

/**
 * Builds a string containing all required properties and their ranges.
 *
 * @example
 * ```javascript
 * const formatted = format_criteria(raw) // (3.2-4.3) nmol
 * const formatted = format_criteria(raw_w_address) // (3.2-4.3) nmol | a1
 * ```
 * To make symbol \u000A (new line) working add the next css rule to the element in which criteria placed:
 * `style="white-space: pre-line;"`
 *
 * @param {import('@/operation_types/giveaway/GiveawayWorkType.d.ts').Criterion} raw_criterion - The raw criterion object.
 * @param {boolean} [include_packaging=true] - Whether to include packaging in parsing. Default is `true`.
 * @returns {string} The formatted string containing all required properties and their ranges.
 */

export function format_criterion(raw_criterion, include_packaging = true) {
  if (!raw_criterion) return null;
  let formed_criteria = "";
  for (const [mod, data] of Object.entries(raw_criterion)) {
    formed_criteria += (mod !== "packaging")
      ? `(${format_num(data.value)}\u00A0-\u00A0${format_num(data.max)})\u00A0${get_base_unit_of_mod(mod)}\u000A` : "";
  }
  if ("packaging" in raw_criterion && include_packaging) {
    const { packaging } = raw_criterion;
    formed_criteria += format_packaging(packaging);
  }
  return formed_criteria.trim();
}

// utility functions to filter out hss with skipped only sample and with leftover-samples only
// (e.g. when we need to display a partial easy-map there is no need to show the remaining ss as a transfer)
export const get_op_by_id = (target_id, ops) => ops.find((op) => op.id === target_id);

export const intersects_w_one_input_ss = (ss_out) => (ss_in) => ss_out.housing_id === ss_in.housing_id
  && hash_address(ss_out.address) === hash_address(ss_in.address);

/**
 * Returns true if passed ss is a leftover of a partial transfer.
 * A leftover is defined by two conditions:
 *  1. it intersects with some ss_in in the same housing by address
 *  2. its operation_in doesn't have other s ss_out (important for merge in the same housing in the same well).
 * @param {SampleState} ss_out
 * @param {Operation[]} ops
 * @param {Record<number, Housing>} h_by_hs
 * @returns {Boolean}
 */
export const is_a_leftover = (ss_out, ops, h_by_hs) => {
  const op = get_op_by_id(ss_out.operation_in.id, ops);
  const sss_in_w_housings = op.sample_states_in.map((ss_in) => ({
    ...ss_in,
    housing_id: h_by_hs.in[ss_in.housing_state_id].id,
  }));
  const ss_out_w_housing = {
    ...ss_out,
    housing_id: h_by_hs.out[ss_out.housing_state_id].id,
  };
  return sss_in_w_housings.some(intersects_w_one_input_ss(ss_out_w_housing)) && op.sample_states_in.length === 1;
};

export const not_w_leftovers_only = (hs, ops, h_by_hs) => !hs.sample_states.every((ss) => is_a_leftover(ss, ops, h_by_hs));

/**
 * convenience function, needed cuz there are (at the moment) different formats of properties inside sample_state.meta.properties:
 * tubes -> int
 * count -> int
 * volume/mass/amount/scale -> Measurement object
 * @param {Record<string, number | import("@/types/General.d.js").Measurement>} properties_obj
 * @param {"tubes" | "count" | "volume" | "mass" | "amount"} property
 */
export const get_sample_state_property_value = (properties_obj, property) => {
  if (!properties_obj) return null;
  if (["tubes", "count"].includes(property)) {
    return properties_obj[property] ?? null;
  }
  return properties_obj[property]?.value || null;
};

export const weighed_ss_properties = ["tubes", "count", "volume", "mass", "amount"];
/**
 * @param {SampleState} ss
 * @returns {[import("@/types/General.d.js").Property, import("@/types/General.d.js").Measurement] | null}
 */
export const get_main_sample_state_property = (ss) => {
  if (!ss.meta?.properties) {
    return null;
  }
  const ss_props = ss.meta?.properties;
  for (const prop of weighed_ss_properties) {
    if (prop in ss_props && ![null, 0].includes(ss_props[prop]?.value)) {
      return [prop, ss_props[prop]];
    }
  }
  return null;
};

/**
 * Returns the most informative of the available physical properties of the provided sample_state.
 * In the order of descending priority: "volume" > "mass" > "count" > "amount".
 * @param {SampleState} ss
 * @returns {string | null}
 */
export const get_main_sample_state_value = (ss) => {
  if (!ss.meta?.properties) {
    return null;
  }
  const ss_props = ss.meta?.properties;
  for (const prop of weighed_ss_properties) {
    if (prop in ss_props && ss_props[prop]?.value !== null) {
      return prettify(ss_props[prop].value, prop);
    }
  }
  return null;
};

/**
 * Used to prioritize sample_states (e.g. when auto-selecting for check-in).
 * Lower sample_ids indicate older sample, lower label_ids indicate older tube.
 * @param {import("@/__fixtures__/sample_state/sample_state.js").SampleState} a
 * @param {import("@/__fixtures__/sample_state/sample_state.js").SampleState} b
 * @returns {number}
 */
export const sort_by_oldest_first = (a, b) => {
  if (a.sample_id === b.sample_id) {
    return a.label_id - b.label_id;
  }
  return a.sample_id - b.sample_id;
};

/**
 * Returns true, if ss doesn't have meta or meta.properties - w/o those fields sample states cannot be manipulated.
 * @param {import("@/__fixtures__/sample_state/sample_state.js").SampleState} ss
 * @returns {Boolean}
 */
export const is_ss_meta_broken = (ss) => !Object.hasOwn(ss.meta ?? {}, "properties");

/**
 * Returns a formatted string based on the passed 'properties' parameter.
 * @param { Object } properties
 * @returns
 */
export const get_pretty_ss_properties_str = (properties) => {
  const {
    mass,
    volume,
    count,
    tubes,
  } = properties ?? {};
  let amount = volume ? (volume?.value ?? 0) : (mass?.value ?? 0);
  if (amount) amount = prettify(amount, volume ? "volume" : "mass");

  let value = "";
  if (count && tubes) {
    value = `${tubes} pcs (${count} pcs each${amount ? ` of ${amount}` : ""})`;
  } else if (count) {
    value = `${count} ${amount ? `(${amount} each)` : "pcs"}`;
  } else if (tubes) {
    value = `${tubes} ${amount ? `(${amount} each)` : "pcs"}`;
  } else {
    value = amount || "";
  }
  return value;
};
